import React, { useState, useEffect } from 'react';
import { Card, Layout, FormLayout, TextField, Page, Frame, Toast, VerticalStack, Text, RadioButton, Button } from '@shopify/polaris';
import Joyride from 'react-joyride';

const socialMediaIcons = [
  { style: 'long', label: 'Long Button' },
  { style: 'circle', label: 'Circle Icon' },
  { style: 'curve', label: 'Curve Edge Icon' },
  { style: 'whitelong', label: 'White Long Button' },
  { style: 'whitecircle', label: 'White Circle Icon' },
  { style: 'whitecurve', label: 'White Curve Edge' },
];

const Customize = () => {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [buttonStyle, setButtonStyle] = useState('long');
  const [iconWidth, setIconWidth] = useState(50);
  const [iconHeight, setIconHeight] = useState(50);
  const [iconSize, setIconSize] = useState(25);
  const [iconSpace, setIconSpace] = useState(10);
  const [iconCurve, setIconCurve] = useState(5);
  const [iconText, setIconText] = useState("Sign with ");
  const [socialIconsArray, setSocialIconsArray] = useState([]);
  const [runTour, setRunTour] = useState(false);
  const tourSteps = [
    {
      target: '.first-button',
      content: 'In preview you will get to know which social icons is active',
      disableBeacon: true,
    },
    {
      target: '.second-button',
      content: 'Here you can select options to modify icons to your need',
      disableBeacon: true,
    },
  ];
  const iconsColor = {
    "discord": "#7289da",
    "google": "#db4a39",
    "facebook": "#3b5998",
    "linkedin": "#1da1f2",
    "amazon": "#ff9900",
    "line": "#00c300",
    "windowslive": "#0078D7",
    "yahoo": "#720e9e",
    "apple": "#000000",
    "twitch": "#6441a4",
    "kakao": "#f9e000",
    "naver": "#2db400",
    "github": "#4078c0",
    "reddit": "#ff5700",
    "slack": "#4a154b",
    "spotify": "#1DB954",
    "twitter": "#1D9BF0",
  };
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.configurations.appConfig
            if (typeof dataStore === 'object' && dataStore !== null) {
              const enabledIcons = Object.keys(dataStore).filter(appName => {
                const appData = dataStore[appName];
                return appData && appData.isChecked === true;
              });
              setSocialIconsArray(enabledIcons);
            } else {
              console.error('DataStore is not an object:', dataStore);
            }
            const dataStore1 = data.integrations.customization;
            setButtonStyle(dataStore1.buttonStyle);
            setIconWidth(dataStore1.iconWidth);
            setIconHeight(dataStore1.iconHeight);
            setIconSize(dataStore1.iconSize);
            setIconCurve(dataStore1.iconCurve);
            setIconSpace(dataStore1.iconSpace);
            setIconText(dataStore1.iconText);
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to fetch settings');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchData();
    }
    const tourShown = localStorage.getItem('tourShown');
    if (tourShown) {
      setRunTour(false);
    }

  }, [storeDomain, socialIconsArray, loading]);

  const handleSubmit = async () => {

    const customization = {
      buttonStyle,
      iconWidth,
      iconHeight,
      iconSize,
      iconCurve,
      iconSpace,
      iconText,
    };
    console.log(customization);
    const response = await fetch(`/api/update_data?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({customization}),
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');
    }
    setShowToast(true);
  };

  const joyrideOptions = {
    steps: tourSteps,
    run: runTour,
    continuous: true,
    showSkipButton: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      buttonNext: {
        background: 'black',
      },
    },
  };

  const handleJoyrideCallback = (data) => {
    if (data.status === 'finished' || data.status === 'skipped') {
      localStorage.setItem('tourShown', 'true');
      setRunTour(false);
    }
  };

  const startTour = () => {
    localStorage.removeItem('tourShown');
    setRunTour(true);
  };
  

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleInputChange = (key, value) => {
    switch (key) {
      case 'buttonStyle':
        setButtonStyle(value);
        break;
      case 'iconWidth':
        setIconWidth(value);
        break;
      case 'iconHeight':
        setIconHeight(value);
        break;
      case 'iconSize':
        setIconSize(value);
        break;
      case 'iconSpace':
        setIconSpace(value);
        break;
      case 'iconCurve':
        setIconCurve(value);
        break;
      case 'iconText':
        setIconText(value);
        break;
      default:
        break;
    }
  };

  const getContentBasedOnStyle = (style) => {
    
    const commonStyle = {
      overflow: 'hidden',
      margin: `${iconSpace}px`,
      width: `${iconWidth}px`,
      height: `${iconHeight}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    switch (style) {
      case 'long':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: `${iconSpace}px 0px`,
                borderRadius: `${iconCurve}px`,
                padding: '10px',
                width: `${Number(iconWidth) + 200}px`,
                height: `${iconHeight}px`,
                color: 'white',
                backgroundColor: `${iconsColor[icon]}`,
              }}
              className="second-button">
              <img
                src={require(`../assets/${icon}_icon.png`)}
                alt={icon}
                style={{ width: '30px', height: '30px', marginRight: '10px' }}
              />
              <span style={{ fontSize: `${Number(iconSize) - 10}px` }}>{iconText}{capitalizeFirstLetter(icon)}</span>
            </div>
          ))}
          </div>
        );
      case 'whitelong':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid black',
                margin: `${iconSpace}px 0px`,
                borderRadius: `${iconCurve}px`,
                padding: '10px',
                backgroundColor: 'white',
                width: `${Number(iconWidth) + 200}px`,
                height: `${iconHeight}px`,
              }}
            >
              <img
                src={require(`../assets/${icon}-elite.png`)}
                alt={icon}
                style={{ width: '30px', height: '30px', marginRight: '10px' }}
              />
              <span style={{ fontSize: `${Number(iconSize) - 10}px` }}>{iconText}{capitalizeFirstLetter(icon)}</span>
            </div>
            ))}
          </div>
        );
      case 'curve':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
              <div
                key={index}
                style={{
                  ...commonStyle,
                  borderRadius: `${iconCurve}px`,
                  float: 'left',
                  color: 'white',
                  backgroundColor: `${iconsColor[icon]}`,
                }}
              >
                <img
                  src={require(`../assets/${icon}_icon.png`)}
                  alt={icon}
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            ))}
          </div>
        );
      case 'circle':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                ...commonStyle,
                borderRadius: '50%',
                float: 'left',
                color: 'white',
                backgroundColor: `${iconsColor[icon]}`,
              }}
            >
              <img
                src={require(`../assets/${icon}_icon.png`)}
                alt={icon}
                style={{ width: '30px', height: '30px' }}
              />
            </div>
            ))}
          </div>
        );
      case 'whitecurve':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
              <div
                key={index}
                style={{
                  ...commonStyle,
                  borderRadius: `${iconCurve}px`,
                  float: 'left',
                  border: '1px solid black',
                }}
              >
                <img
                  src={require(`../assets/${icon}-elite.png`)}
                  alt={icon}
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            ))}
          </div>
        );
      case 'whitecircle':
        return (
          <div>
            {socialIconsArray.map((icon, index) => (
            <div
              key={index}
              style={{
                ...commonStyle,
                borderRadius: '50%',
                float: 'left',
                border: '1px solid black',
              }}
            >
              <img
                src={require(`../assets/${icon}-elite.png`)}
                alt={icon}
                style={{ width: '30px', height: '30px' }}
              />
            </div>
            ))}
          </div>
        );
      default:
        return (
          <>
          No Apps Is Activated
          </>
        );
    }
  };

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Page
          title="Customize Icons"
          primaryAction={{
            content: 'Save',
            onAction: handleSubmit,
          }}
          secondaryActions={
              <>
                <Button onClick={startTour}>Start Tour</Button>
              </>}
        >
          <Layout>
            <Layout.Section secondary>
              <VerticalStack spacing="loose" distribution="fill" wrap="false">
                <div className="second-button">
                <Card>
                  <FormLayout>
                    {socialMediaIcons.map((icon) => (
                      <RadioButton
                        key={icon.style}
                        label={icon.label}
                        checked={buttonStyle === icon.style}
                        onChange={() => handleInputChange('buttonStyle', icon.style)}
                      />
                    ))}
                    <TextField
                      label="Icon Width"
                      type="number"
                      value={iconWidth}
                      onChange={(value) => handleInputChange('iconWidth', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Height"
                      type="number"
                      value={iconHeight}
                      onChange={(value) => handleInputChange('iconHeight', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Size"
                      type="number"
                      value={iconSize}
                      onChange={(value) => handleInputChange('iconSize', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Space"
                      type="number"
                      value={iconSpace}
                      onChange={(value) => handleInputChange('iconSpace', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Curve"
                      type="number"
                      value={iconCurve}
                      onChange={(value) => handleInputChange('iconCurve', value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Icon Text"
                      value={iconText}
                      onChange={(value) => handleInputChange('iconText', value)}
                      autoComplete="off"
                    />
                  </FormLayout>
                </Card>
                </div>
              </VerticalStack>
            </Layout.Section>
            <Layout.Section secondary>
              <VerticalStack spacing="loose" distribution="fill" wrap="false">
                <div className="first-button">
                  <Card>
                      <Text variant="headingMd" as="h2">Preview Social Icons:</Text>
                      {getContentBasedOnStyle(buttonStyle)}
                  </Card>
                </div>
              </VerticalStack>
            </Layout.Section>
          </Layout>
          {showToast && (
            <Toast
              content={toastMessage}
              onDismiss={() => setShowToast(false)}
            />
          )}
          <Joyride {...joyrideOptions} callback={handleJoyrideCallback} />
        </Page>
        <Page></Page>
        <Page></Page>
        <Page></Page>
      </Frame>
    </div>
  );
};

export default Customize;
