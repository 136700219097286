import { useEffect, useState } from 'react';
import { Page, LegacyCard, DataTable, Select } from '@shopify/polaris';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Google', value: 'google' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'LinkedIn', value: 'linkedin' },
];

export default function UserReport() {
  
  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [customerData, setCustomerData] = useState([]);
  const [filterValue, setFilterValue] = useState('all');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_allusers?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data.customers)) {
            setCustomerData(data.customers);
          } else {
            console.error('Invalid customer data:', data);
          }
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  const filteredCustomers =
    filterValue === 'all'
      ? customerData
      : customerData.filter(
          (customer) =>
            customer.note &&
            customer.note
              .toLowerCase()
              .includes(filterValue.toLowerCase())
        );

  const rows = filteredCustomers.map((customer) => [
    customer.email,
    `${customer.first_name} ${customer.last_name}`,
    customer.note,
    customer.updated_at,
  ]);

  return (
    <Page title="Social Login User Reports">
      <LegacyCard>
        <Select
          options={filterOptions}
          value={filterValue}
          onChange={handleFilterChange}
        />
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text']}
          headings={['Email', 'Name', 'Notes', 'Updated_at']}
          rows={rows}
        />
      </LegacyCard>
    </Page>
  );
}
