import { useState, useEffect } from 'react';
import { AppProvider, Card, Form, FormLayout, TextField, Button, Page, Frame, Collapsible, TextContainer, List, Image, Checkbox, Toast, Modal, Link, HorizontalStack, ProgressBar } from '@shopify/polaris';
import { googleElite, facebookElite, linkedinElite, discordElite, amazonElite, lineElite, windowsliveElite, yahooElite, appleElite, twitchElite, kakaoElite, naverElite, slackElite, githubElite, redditElite, spotifyElite, twitterElite, supportForm, socialYoutube, socialDocument } from "../assets";
import enTranslations from '@shopify/polaris/locales/en.json';
import Joyride from 'react-joyride';

export default function HomePage() {

  const [registrationCount, setRegistrationCount] = useState(0);
  const originUrl = window.location.host;
  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showEmptyFieldWarning, setShowEmptyFieldWarning] = useState(false);
  const fullHostname = originUrl;
  const parts = fullHostname.split('.');
  const domainName = parts.slice(-2).join('.');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const callbakcUrl = `https://${originUrl}/oauth/callback`;
  const [activeProPlans, setActiveProPlans] = useState(false);
  const [activeBasicPlans, setActiveBasicPlans] = useState(false);
  const [socialServices, setSocialServices] = useState([
    {
      name: 'Google',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '326852937312-03ru3sp9nnbusdv3qc6vkth3bkcadb1d.apps.googleusercontent.com',
      setClientId: () => {},
      clientSecret: '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',
      setClientSecret: () => {},
      clientScope: 'email',
      setClientScope: () => {},
      videoLink: "https://youtu.be/2s8B4nhvikI",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/google-login-in-shopify-sso/",
      developerlink: <Link url="https://console.cloud.google.com/project/" target="_blank">https://console.cloud.google.com/project/</Link>,
      instructions: [
        'After sign-in, click on "Create project", enter a name for the project, and optionally, edit the provided Project ID. Click Create.',
        'In the Left side Menu click on ≡ bar button and go to "APIs & Services -> OAuth consent screen."',
        'Select "external" as user type and click on "Create".',
        'Enter "App Name" and User support "Email" and then scroll down to add Authorized domain as "'+domainName+'"',
        'Enter Developer contact "email" and click on "Save and Continue".',
        'On the Scopes screen click on Add or Remove Scopes. Check ".../auth/userinfo.email" and ".../auth/userinfo.profile". Scroll down and click on Update again Scroll down and click on Save and Continue.',
        'In the Left side Menu, Click on "Credentials" then click on Create Credential from dropdown select "Oauth client ID".',
        'In Application Type drop down, Select "Web Application". Enter "Name" and scroll down to add "'+callbakcUrl+'/google" in Authorized Redirect URL, and click on "Create".',
        'Copy "Client ID" and "Client Secret" and paste it on the above field Application ID and Application Secret, Click on "Save & Test Configuration".',
        'Click on Left side Menu and go to "APIs & Services -> OAuth consent screen". Under Publishing Status Click on "Publish App" and "Confirm".',
        'Enter "email" in Scope. Click on the Save & Test Application button.',
        <div className="fifth-button">If you are facing any issues, please contact technical assistance by sending an email to info@elitecontrivers.com.</div>
      ],
      isCollapsed: true,
    },
    {
      name: 'Facebook',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '625457026359643',
      setClientId: () => {},
      clientSecret: '9217dda8b759b812a996e923991a5c0b',
      setClientSecret: () => {},
      clientScope: 'email, public_profile',
      setClientScope: () => {},
      videoLink: "https://youtu.be/Afk3Gb2O6NM",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/facebook-login-in-shopify-sso/",
      developerlink: <Link url="https://developers.facebook.com/apps" target="_blank">https://developers.facebook.com/apps</Link>,
      instructions: [
        'Click on "Create App". Select "Set up Facebook Login" and click on "Next".',
        'In the Platform options, select "Website". Under "Are you building a game?", select "No, I\'m not building a game" and click on "Next".',
        'Enter the App Name and App Contact Email. Click on the "Create App" button and complete the Security Check.',
        'In the left panel, click on "Settings" and select "Basic".',
        'Enter "'+domainName+'" in the App Domain field. Provide the URL for your Privacy Policy and Terms of Service in their respective sections.',
        'Under the "User Data Deletion" section, enter the link to your Data Deletion Page, where users can delete their accounts on your site.',
        'Upload an App Icon and select the Category that best represents your website. Then, click on "Save Changes".',
        'Copy the App ID and App Secret and paste them into the fields above. Include "email, public_profile" in the scope.',
        'Go back to the developer app. In the left panel, click on "Products". In the Facebook Login section, click on "Configure" and select "Settings".',
        'Scroll down and add "'+callbakcUrl+'/facebook" as a Valid OAuth Redirect URI. Click on "Save Changes".',
        'In the left panel, scroll down and click on "Go live". In the Facebook Login section, click on the "Review" button.',
        'In the "Authentication and account creation" section, click on "Edit" and click on the "Add" button in the email section.',
        'Finally, click on the "Save & Test Application" button.',
        'If you are facing any issues, please contact technical assistance by sending an email to info@elitecontrivers.com.'
      ],
      isCollapsed: true,
    },
    {
      name: 'LinkedIn',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '77ghmn18x97h0p',
      setClientId: () => {},
      clientSecret: 'a59WTgARuiUv4OI9',
      setClientSecret: () => {},
      clientScope: 'openid profile email',
      setClientScope: () => {},
      videoLink: "https://youtu.be/Yzr57-fY2mU",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/linkedin-login-in-shopify-sso/",
      developerlink: <Link url="http://developer.linkedin.com/" target="_blank">http://developer.linkedin.com/</Link>,
      instructions: [
        'Log in with your account. Click on "Create Apps".',
        'Enter the App Name, LinkedIn page URL or name, Privacy Policy URL, and upload the app logo. Check the Legal agreement and click on "Create app".',
        'Under the "Products" section, locate "Sign In with LinkedIn" and click on "Request access" for the same. Check the API Terms of Use and click on "Request access".',
        'Go to the "Auth" tab and enter "'+callbakcUrl+'/linkedin" as the Authorized Redirect URL. Click on "Update".',
        'Scroll up to the "Application credentials" section to find your Client ID and Client Secret. Copy these and paste them into the fields above.',
        'Wait for LinkedIn to approve your permission. Enter "openid profile email" in the Scope field.',
        'Click on the "Save & Test Application" button.',
        'If you are facing any issues, please contact technical assistance by sending an email to info@elitecontrivers.com.'
      ],
      isCollapsed: true,
    },
    {
      name: 'Discord',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '1149290182953140254',
      setClientId: () => {},
      clientSecret: 'caXbnyXAgJ2bqEEjpRp8iCNjjtALdQWc',
      setClientSecret: () => {},
      clientScope: 'identify',
      setClientScope: () => {},
      videoLink: "https://youtu.be/-0puj0pKR-0",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/discord-login-in-shopify-sso/",
      developerlink: <Link url="https://discordapp.com/developers/applications" target="_blank">https://discordapp.com/developers/applications</Link>,
      instructions: [
        'Sign in with your Discord developer account.',
        'Click on the "New Application" button and enter a name for your app. Check the Developer Terms of Service and click on "Create".',
        'Select "OAuth2" from the left panel.',
        'Click on "Add redirect" and enter "'+callbakcUrl+'/discord" as the redirect URL.',
        'Click on "Reset Secret" and copy the Client ID and Client Secret. Paste them into the corresponding fields above.',
        'Enter "identify" as the scope. Click on the "Save & Test Application" button.',
        'If you are facing any issues, please contact technical assistance by sending an email to info@elitecontrivers.com.'
      ],
      isCollapsed: true,
    },
    {
      name: 'Amazon',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: 'amzn1.application-oa2-client.f907b0f70a594632a11cebd0ead4e679',
      setClientId: () => {},
      clientSecret: 'amzn1.oa2-cs.v1.f2df39d1a5524117fd231068656953b426e41447f8fc733c7c145f92734e53e6',
      setClientSecret: () => {},
      clientScope: 'profile',
      setClientScope: () => {},
      videoLink: "https://youtu.be/dBRfhqoNzlM",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/amazon-login-in-shopify-sso/",
      developerlink: <Link url="http://login.amazon.com" target="_blank">http://login.amazon.com</Link>,
      instructions: [
		    'On the developer homepage, in the upper right corner, click Developer Console.',
        'On the Developer Console homepage, click Login with Amazon, Click on the Create a New Security Profile button and enter a Name, Description, and Privacy Notice URL for your app. Click on Save.',
        'click the configuration icon in the Manage column and then click Web Settings.',
        'In the bottom right section click on Edit and Enter "'+callbakcUrl+'/amazon" in the Allowed Return URLs and click on Save button.',
        'Copy the Client Id and Client Secret and Paste them into the fields above.',
        'Enter "profile" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.'
      ],
      isCollapsed: true,
    },
    {
      name: 'Line',
      isChecked: true,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '2000672311',
      setClientId: () => {},
      clientSecret: '3c853b55513f4f9429321f0d799eecc0',
      setClientSecret: () => {},
      clientScope: 'profile openid email',
      setClientScope: () => {},
      videoLink: "https://youtu.be/S1vg50W0fLA",
      guideLink: "",
      developerlink: <Link url="https://developers.line.biz/console/register/line-login/provider" target="_blank">https://developers.line.biz/console/register/line-login/provider</Link>,
      instructions: [
        'Download the Line Application(app) and sign up/login into the app in the mobile.',
        'Click on  login with LINE Account  then either use Login credential of your mobile application or Select New QR code login and scan the code through mobile phone',
        'On create channel page, from channel type dropdown select LINE login, Enter all the required fields and click on web for App type. Click on create',
        'Click on LINE login tab and edit callback URL as "'+callbakcUrl+'/line",  Click on Update ',
        'Click on Basic setting tab, Paste your Channel ID and Channel Secret provided by line into the fields above.',
        'Enter "profile openid email" as Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.'
      ],
      isCollapsed: true,
    },
    {
      name: 'Windowslive',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade" target="_blank">https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade</Link>,
      instructions: [
        'Click on New Registration',
        'Name your App Name and select Accounts in any organizational directory (Any Azure AD directory - Multitenant) and ',
        'personal Microsoft accounts (e.g. Skype, Xbox) as supported accounts types. click on Register.',
        'Click on Authentication from left side menu.',  
        'Click on Add a platform and select Web',
        'Enter "'+callbakcUrl+'/windowslive", as Redirect URL and click on Configure',
        'Go to Overview tab and Copy the Application (client) ID. This is your Application ID .',
        'Click on Add a certificate or secret and click on New Client Secret and enter Description and click on Add',
        'Copy Value This is your Application Secret',
        'Enter "wl.emails" as Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Yahoo',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/yahoo-login-in-shopify-sso/",
      developerlink: <Link url="https://developer.yahoo.com" target="_blank">https://developer.yahoo.com</Link>,
      instructions: [
        'On the page, Click on the Create an App button.',
        'Enter Application Name, Description, Homepage URL and "'+callbakcUrl+'/yahoo" as Redirect URI(s) and click on Create App.',
        'Copy the Client ID and Client Secret from this page and Paste them into the fields above.',
        'Enter "email openid" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Apple',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "https://youtu.be/aPHVFOyIjS8",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/apple-login-in-shopify-sso/",
      developerlink: <Link url="https://developer.apple.com" target="_blank">https://developer.apple.com</Link>,
      instructions: [
        'Click on Certificates under Certificates, IDs & Profiles section.',
        'In the left section menu Click on Identifiers and after that click on the Register an App ID.',
        'Click on Continue for the next 2 pages.',
        'Enter description and Bundle ID for the App ID. (The Bundle ID is best when it’s a reverse-dns style string.)',
        'In the capabilities section scroll down and check on sign with apple option and Click on Continue and then on next page click on Register.',
        'On the top right click on App IDs and select Services IDs.',
        'Click on Register an Services ID and on next page click on Continue.',
        'Enter Description, Identifier, and click on the Continue and on the next page click on Register button.',
        'Click on the created Identifiers name. Enable Sign in with Apple and click on Configure button.',
        'Enter '+domainName+' in Domains and Subdomains and "'+callbakcUrl+'/apple" in Return URLs and click on the Next button.',
        'Click on Done on next page click on Continue on next page click on Save',
        'Copy the Identifier name as Application ID and Paste it into the field above.',
        'Enter Apple Client Secret as blank and Scope as email. Click on the Test Configuration button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Twitch',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://dev.twitch.tv/console/apps" target="_blank">https://dev.twitch.tv/console/apps</Link>,
      instructions: [
        'Click on Register Your Application.',
        'Then enter name of your application in Name field. Enter "'+callbakcUrl+'/twitch" in the OAuth Redirect URL. Then select your website Category.',
        'Click on Create button.',
        'After creating application click on manage button which in front of your created application.',
        'Click on New Secret button. It will generate Client Secret key. Copy the Client ID and Client Secret from this page and Paste them into the Client ID and Client Secret fields above.',
        'Enter "user:read:email" as a Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Kakao',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "https://youtu.be/VEbLh_ZNycQ",
      guideLink: "",
      developerlink: <Link url="https://developers.kakao.com" target="_blank">https://developers.kakao.com</Link>,
      instructions: [
        'Click on My Application (내 애플리케이션) in the top menu. Click on Add An Application (애플리케이션 추가하기) button.',
        'Add an App Icon (앱 아이콘), enter an App name (앱 이름) and Business name (사업자명) and click on the Save button(저장).',
        'Select the created app, scroll down & click on Setting up platform in platform (플랫폼 추가) section. Select Web platform registration in the Web (웹), section.',
        'Enter '+domainName+' in Site Domain Section. Click on Save.',
        'Click on Go to Register link displayed in Web Section. In Redirect URI (리디렉션 경로), enter "'+callbakcUrl+'/kakao" as the Redirect URI and click on Save (저장).',
        'On the same page, enable the State(상태) for Kakao Login displayed in Activation Settings (활성화 설정).',
        'In the left side menu, click on Consent Item(동의 항목) from under Kakao Login under product Settings.',
        'Under Privacy click on set for Profile information(nickname/profile picture) 프로필 정보(닉네임/프로필 사진).',
        'Select Required Consent under Consent Phase and enter Purpose of Consent (동의 목적) and Save.',
        'Click on set for Kakao Account(email). Select Accept Choice (선택 동의) from the Consent phase. Enter Purpose of Consent (동의 목적) and Save.',
        'Click on App Key(앱 키) under App Settings(앱 설정) from the left menu. Copy the REST API 키 from this page and paste it into the Application ID on the plugin’s instructions page.',
        'Select Security under Product Settings from the left menu. Click on Code Generation (코드) button.',
        'Click on Produce, copy the Code (코드) from this page and paste it into the Application Secret.',
        'Enter scope as "account_email". Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Naver',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "https://youtu.be/RkiTH2D-bpo",
      guideLink: "",
      developerlink: <Link url="https://developers.naver.com/apps" target="_blank">https://developers.naver.com/apps</Link>,
      instructions: [
        'In Application select Application registration.',
        'Check I accept the Terms and Conditions. then on confirm.',
        'On Application registration Enter Application name and select category.',
        'Select Nearo (Login with Naver ID) as Usage API. Select all authority such as Member name, e-mail, Nickname, Profile Picture, gender, birthday, Age range.',
        'Add environment as PC website enter service URL '+domainName+' your domain name.',
        'Enter "'+callbakcUrl+'/naver" as Login Callback URL with Naver ID.',
        'Copy the Client Id and Client Secret and paste them into the fields above.',
        'Enter "email" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Github',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://github.com/settings/developers" target="_blank">https://github.com/settings/developers</Link>,
      instructions: [
        'Click on the New OAuth App.',
        'Enter an Application Name and enter Homepage URL as https://'+domainName,
        'Enter "'+callbakcUrl+'/github" in the Authorization callback URL field. Click on Register application.',
        'Click on Register application.',
        'In front of Client Secret, click on Generate a new client secret.',
        'Copy the Client Id and Client Secret and paste them into the fields above.',
        'Enter "user" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Reddit',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://www.reddit.com/prefs/apps" target="_blank">https://www.reddit.com/prefs/apps</Link>,
      instructions: [
        'On the create application page. Enter name for your app.',
        'Select web app as your plateform and app description.', 
        'Enter https://'+domainName+' in about url.',
        'Enter "'+callbakcUrl+'/reddit" as redirect uri',
        'Copy web app as Client Id and secret as Client Secret from the apps Settings and Paste them into the fields above.',
        'Enter "identity" as Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Slack',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://api.slack.com/apps" target="_blank">https://api.slack.com/apps</Link>,
      instructions: [
        'Click on create new app and on the popup, select From Scratch.',
        'Now enter your App Name and pick workspace.',
        'In the left side dashboard under Features, click on OAuth & Permissions',
        'Add "'+callbakcUrl+'/slack" in Redirect URL section, then click on Add and then Save URLs.',
        'Now in the left side dashboard under Settings, click on the Basic information.',
        'Copy the Client Id and Client Secret and paste them into the fields above.', 
        'Enter "users:read" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Spotify',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "",
      guideLink: "",
      developerlink: <Link url="https://developer.spotify.com/documentation/web-api" target="_blank">https://developer.spotify.com/documentation/web-api</Link>,
      instructions: [
        'Click on create new app. Now enter your App Name and pick workspace.',
        'Add "'+callbakcUrl+'/spotify" in Redirect URL section, then click on Add and then Save URLs.',
        'Now in the right side dashboard , click on the Settings.',
        'Copy the Client Id and Client Secret and paste them into the fields above.', 
        'Enter "user-read-email" in Scope. Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
    {
      name: 'Twitter',
      isChecked: false,
      isPreConfigure: false,
      setIsChecked: () => {},
      clientId: '',
      setClientId: () => {},
      clientSecret: '',
      setClientSecret: () => {},
      clientScope: '',
      setClientScope: () => {},
      videoLink: "https://youtu.be/-8Miy14856E",
      guideLink: "https://doc.elitecontrivers.com/shopify-elite-social-login/twiter-login-in-shopify-sso/",
      developerlink: <Link url="https://developer.twitter.com/en/portal/dashboard" target="_blank">https://developer.twitter.com/en/portal/dashboard</Link>,
      instructions: [
        'Click on create new app inside project. Now enter your App Name and pick workspace.',
        'Add "'+callbakcUrl+'/twitter" in Redirect URL section, then click on Add and then Save URLs.',
        'Now in The dashboard, click on the tokens and keys sections.',
        'Copy the Client Id and Client Secret and paste them into the fields above.', 
        'Enter scope as "tweet.read users.read offline.access". Click on the Save & Test Application button.',
        'If you are facing any issues drop a mail on info@elitecontrivers.com for technical assistance.',
      ],
      isCollapsed: true,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setRegistrationCount(data.custom.registrationCount || 0);
            const dataStore = data.configurations;
            setSocialServices((prevSocialServices) =>
              prevSocialServices.map((service) => {
                function setDefaultServiceValues(service, serviceName, property) {
                  const serviceConfig = dataStore.appConfig?.[serviceName] || {};
                  return {
                    ...service,
                    [property]: serviceConfig[property] || false,
                    isPreConfigure: serviceConfig.isPreConfigure || false,
                    clientId: serviceConfig.clientId || '',
                    clientSecret: serviceConfig.clientSecret || '',
                    clientScope: serviceConfig.scope || '',
                  };
                }
                
                if (service.name === 'Google') {
                  return setDefaultServiceValues(service, 'google', 'isChecked');
                } else if (service.name === 'Facebook') {
                  return setDefaultServiceValues(service, 'facebook', 'isChecked');
                } else if (service.name === 'LinkedIn') {
                  return setDefaultServiceValues(service, 'linkedin', 'isChecked');
                } else if (service.name === 'Discord') {
                  return setDefaultServiceValues(service, 'discord', 'isChecked');
                } else if (service.name === 'Amazon') {
                  return setDefaultServiceValues(service, 'amazon', 'isChecked');
                } else if (service.name === 'Line') {
                  return setDefaultServiceValues(service, 'line', 'isChecked');
                } else if (service.name === 'Windowslive') {
                  return setDefaultServiceValues(service, 'windowslive', 'isChecked');
                } else if (service.name === 'Yahoo') {
                  return setDefaultServiceValues(service, 'yahoo', 'isChecked');
                } else if (service.name === 'Apple') {
                  return setDefaultServiceValues(service, 'apple', 'isChecked');
                } else if (service.name === 'Twitch') {
                  return setDefaultServiceValues(service, 'twitch', 'isChecked');
                } else if (service.name === 'Kakao') {
                  return setDefaultServiceValues(service, 'kakao', 'isChecked');
                } else if (service.name === 'Naver') {
                  return setDefaultServiceValues(service, 'naver', 'isChecked');
                } else if (service.name === 'Reddit') {
                  return setDefaultServiceValues(service, 'reddit', 'isChecked');
                } else if (service.name === 'Slack') {
                  return setDefaultServiceValues(service, 'slack', 'isChecked');
                } else if (service.name === 'Github') {
                  return setDefaultServiceValues(service, 'github', 'isChecked');
                } else if (service.name === 'Spotify') {
                  return setDefaultServiceValues(service, 'spotify', 'isChecked');
                } else if (service.name === 'Twitter') {
                  return setDefaultServiceValues(service, 'twitter', 'isChecked');
                }
                return service;
              })
            );
  
            console.log('Fetch settings successfully');
          } else {
            console.error('Failed to save settings');
          }
        }

        const response1 = await fetch(`/api/active_plans?shop=${storeDomain}`);
        const data1 = await response1.json();
        console.log(data1[0].status);
        if(data1[0].name === "Basic Plan"){
          setActiveBasicPlans(true);
        } else if (data1[0].name === "Premium Plan"){
          setActiveProPlans(true);
        }

      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    const tourShown = localStorage.getItem('tourShown');
    if (tourShown) {
      setRunTour(false);
    }

  }, [storeDomain]); 
  
  const handleJoyrideCallback = (data) => {
    console.log(data);
    if (data.status === 'finished' || data.status === 'skipped') {
      localStorage.setItem('tourShown', 'true');
      setRunTour(false);
    }
  };

  const startTour = () => {
    toggleCollapse(0);
    localStorage.removeItem('tourShown');
    setRunTour(true);
  };

  const tourSteps = [
    {
      target: '.first-button',
      content: 'Enable & Click on "Google Configuration" to Configure It',
      disableBeacon: true,
    },
    {
      target: '.second-button',
      content: 'Follow the Intructions given below to Setup the Application.',
      disableBeacon: true,
    },
    {
      target: '.third-button',
      content: 'Click on Test Configuration Button to check if configuration is properly setup',
      disableBeacon: true,
    },
    {
      target: '.fourth-button',
      content: 'A setup video is available for detailed configuration.',
      disableBeacon: true,
    },
    {
      target: '.fifth-button',
      content: 'Contact us if you face any difficulty or you have any sepcific requirement',
      disableBeacon: true,
    },
  ];

  const joyrideOptions = {
    steps: tourSteps,
    run: runTour,
    continuous: true,
    showSkipButton: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      buttonNext: {
        background: 'black',
      },
    },
  };
  
  const handleSupport = async () => {

    setIsSubmitting(true);
    const contact = {
      name: storeDomain,
      email: email,
      message: message,
    };

    const response = await fetch(`/api/contact_support?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({contact}),
    });

    if (response.ok) {
      console.log("Contact ", response.status);
    } else {
      console.error('Failed to submit contact');
    }
    setEmail('');
    setMessage('');
    setIsModalOpen(false);
    setIsSubmitting(false);
    setToastMessage('Thanks for submitting your query!');
    setShowToast(true);
  };

  const handleSubmit = async () => {

    const missingCredentials = socialServices
      .filter((service) => service.isChecked && (!service.clientId || !service.clientSecret))
      .map((service) => service.name);

    if (missingCredentials.length > 0) {
      const missingApps = missingCredentials.join(', ');
      alert(`Please fill in Client ID and Client Secret for the following apps: ${missingApps}.`);
      return;
    }

    const selectedServices = socialServices.filter((service) => service.clientId);
  
    const appConfig = selectedServices.reduce((acc, service) => {
      acc[service.name.toLowerCase()] = {
        isChecked: service.isChecked || false,
        isPreConfigure: service.isPreConfigure || false,
        clientId: service.clientId,
        clientSecret: service.clientSecret,
        scope: service.clientScope,
      };
      return acc;
    }, {});
  
    try {
      const response = await fetch(`/api/update_data?shop=${storeDomain}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appConfig }),
      });
  
      if (response.ok) {
        console.log(response.status);
        setToastMessage('Settings saved successfully.');
      } else {
        console.error('Failed to save settings');
        setToastMessage('Failed to save settings.');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setToastMessage('Failed to save settings.');
    }
  
    setShowToast(true);
  };
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleMessageChange = (value) => {
    setMessage(value);
  };

  const handleVisitStoreEmbed = async () => {
    const url = `https://${storeDomain}/admin/themes/current/editor?&context=apps&template=customers/login&appEmbed=9116732c-c940-47cb-acaa-f3ac40a7b26c/elite_app_block&target=mainSection`;
    window.open(url, '_blank');
  };

  const handleInputChange = (index, field, value) => {
    setSocialServices((prevSocialServices) => {
      const updatedServices = [...prevSocialServices];
      if (field === 'isChecked') {
        updatedServices[index][field] = value;
  
        if (value && !updatedServices[index].clientId && !updatedServices[index].clientSecret && !updatedServices[index].isPreConfigure) {
          setShowEmptyFieldWarning(true);
        } else {
          setShowEmptyFieldWarning(false);
        }
  
        if (value) {
          updatedServices[index].isCollapsed = false;
        }
      } else {
        updatedServices[index] = {
          ...updatedServices[index],
          [field]: value,
        };
  
        if (updatedServices[index].clientId || updatedServices[index].clientSecret) {
          setShowEmptyFieldWarning(false);
        }
      }
      return updatedServices;
    });
  }; 

  const toggleCollapse = (index) => {
    const updatedServices = [...socialServices];
    updatedServices[index].isCollapsed = !updatedServices[index].isCollapsed;
    setSocialServices(updatedServices);
  };

  const handleTestConfiguration = (serviceName) => {
    handleSubmit();
    console.log(serviceName.toLowerCase());
    const url = `https://${window.location.hostname}/oauth/login/${serviceName.toLowerCase()}?shop=${storeDomain}&test_config=true`;
    const windowFeatures = 'width=800,height=500';
    window.open(url, '_blank', windowFeatures);
  };

  const handleToastDismiss = () => {
    setShowToast(false);
  };

  const getImageSource = (serviceName) => {
    const serviceImageMap = {
      Google: googleElite,
      Facebook: facebookElite,
      LinkedIn: linkedinElite,
      Amazon: amazonElite,
      Line: lineElite,
      Windowslive: windowsliveElite,
      Yahoo: yahooElite,
      Apple: appleElite,
      Twitch: twitchElite,
      Kakao: kakaoElite,
      Naver: naverElite,
      Reddit: redditElite,
      Github: githubElite,
      Slack: slackElite,
      Spotify: spotifyElite,
      Twitter: twitterElite,
      Discord: discordElite,
    };
    return serviceImageMap[serviceName];
  }

  return (
    <div>
      <Frame>
        <AppProvider i18n={enTranslations}>
          <Page
            title="Configure Auth Providers"
            primaryAction={{
              content: 'Save',
              onAction: handleSubmit,
            }}
            secondaryActions={
              <>
              <div style={{ marginRight: '10px' }}>
              <Button onClick={handleVisitStoreEmbed}>Enable Social Icons</Button>
              </div>
              <Button onClick={startTour}>Start Tour</Button>
              </>
            }
          >
            {activeProPlans && activeProPlans === true ? (<></>) : (
              <Card>
                Remaining Limit for Login/Registration: <b>{registrationCount}/{activeBasicPlans && activeBasicPlans === true ? "1000": "1000"}</b> Per Month
                <div style={{width: 225, float: 'right'}}>
                  {activeBasicPlans && activeBasicPlans === true ? <ProgressBar progress={parseInt(registrationCount) * 0.2} tone="critical" />: <ProgressBar progress={parseInt(registrationCount) * 0.2} tone="critical" />}
                  
                </div>
              </Card>
            )}

            {socialServices.map((service, index) => (
              <div style={{ marginTop: '15px' }} key={index} className="first-button">
              <Card key={index}>
                <Form onSubmit={handleSubmit}>
                  <FormLayout>
                    <div onClick={() => toggleCollapse(index)} style={{backgroundColor: 'white'}}>
                    <HorizontalStack>
                      <Checkbox
                        label=""
                        checked={service.isChecked}
                        onChange={(value) => handleInputChange(index, 'isChecked', value)}
                      />
                      <Button plain>
                        <Image
                          source={getImageSource(service.name)}                            
                          alt={`${service.name} Icon`}
                          width="25"
                          height="25"
                          style={{ marginRight: '0.5rem', marginBottom: '-6px' }}
                        />
                        {`${service.name} Configuration`}
                      </Button>
                    </HorizontalStack>
                    </div>
                    <Collapsible open={!service.isCollapsed} id="service handle">
                      <TextContainer>
                          <Checkbox
                            label={`Enable Pre-configured app for ${service.name}. ("No need to configure app on your end").`}
                            checked={service.isPreConfigure}
                            onChange={(value) => handleInputChange(index, 'isPreConfigure', value)}
                          />
                          <TextField
                            label={`${service.name} Client ID`}
                            value={service.clientId}
                            onChange={(value) => handleInputChange(index, 'clientId', value)}
                            error={showEmptyFieldWarning && !service.clientId ? 'Client ID is required' : undefined}
                            autoComplete='off'
                            disabled={service.isPreConfigure}
                          />
                          <TextField
                            label={`${service.name} Client Secret`}
                            value={service.clientSecret}
                            onChange={(value) => handleInputChange(index, 'clientSecret', value)}
                            error={showEmptyFieldWarning && !service.clientId ? 'Client Secret is required' : undefined}
                            autoComplete='off'
                            disabled={service.isPreConfigure}
                          />
                          <TextField
                            label={`${service.name} Scope`}
                            value={service.clientScope}
                            onChange={(value) => handleInputChange(index, 'clientScope', value)}
                            autoComplete='off'
                            disabled={service.isPreConfigure}
                          />
                          <div style={{ display: 'flex' }}>
                            <div className="third-button">
                              <Button primary onClick={() => handleTestConfiguration(service.name)}>Test Configuration</Button>
                            </div>
                          <span style={{ marginRight: '10px' }}></span>
                          {service.videoLink && (
                            <Button onClick={() => window.open(service.videoLink, '_blank')} >
                              <div className="fourth-button">
                                <Image source={socialYoutube} style={{ color: 'red', width: '20px', height: '20px', marginRight: '8px' }} />
                                <span>Watch Video</span>
                              </div>
                            </Button>
                          )}
                          <span style={{ marginRight: '10px' }}></span>
                          {service.guideLink && (
                            <Button onClick={() => window.open(service.guideLink, '_blank')} >
                              <div style={{ display: 'flex' }}>
                              <Image source={socialDocument} style={{ color: 'red', width: '20px', height: '20px', marginRight: '8px' }} />
                                <span>Check Guide</span>
                              </div>
                            </Button>
                          )}
                          </div>
                        <p><b>{`${service.name} Instructions:`}</b></p>
                        <div style={{ marginTop: '20px' }} className="second-button">
                          <List type="bullet">
                          <List.Item >Go to {service.name} developers account {service.developerlink}</List.Item>
                            {service.instructions.map((instruction, index) => (
                              <List.Item key={index}>{instruction}</List.Item>
                            ))}
                          </List>
                        </div>
                      </TextContainer>
                    </Collapsible>
                  </FormLayout>
                </Form>
              </Card>
              </div>
            ))}
            {showToast && (
              <Toast content={toastMessage} onDismiss={handleToastDismiss} duration={3000} />
            )}
              <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                <button
                  onClick={toggleModal}
                  style={{
                    width: '60px', 
                    height: '60px', 
                    borderRadius: '50%',
                    background: '#2962FF',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={supportForm}
                    alt="Support"
                    style={{
                      width: '40px',
                      height: '40px',
                    }}
                  />
                </button>
              </div>

              <Modal
                  open={isModalOpen}
                  onClose={toggleModal}
                  title="Contact Support"
                  primaryAction={{
                    content: isSubmitting ? 'Sending...' : 'Send',
                    onAction: handleSupport,
                    disabled: isSubmitting,
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: toggleModal,
                    },
                  ]}
                >
              <Modal.Section>
                <TextContainer>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    placeholder='Your Email'
                    onChange={handleEmailChange}
                    autoComplete='off'
                  />
                  <TextField
                    label="Message"
                    multiline={4}
                    value={message}
                    placeholder='Your Message'
                    onChange={handleMessageChange}
                    autoComplete='off'
                  />
                </TextContainer>
              </Modal.Section>
            </Modal>
            <Joyride {...joyrideOptions} callback={handleJoyrideCallback} />
          </Page>
          <Page></Page>
          <Page></Page>
          <Page></Page>
          <Page></Page>
        </AppProvider>
      </Frame>
    </div>
  );
}
