import { Page, Layout, Card, List, Image, VerticalStack } from '@shopify/polaris';
import { configuration, loginPage, customize, socialProvider, disable, accountEnable, loginEnable } from "../assets";

export default function InstructionPage() {
  
  return (
    <Page title="Social Login Guidelines">
      <Layout>
        <Layout.Section>
          <Card>
              <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>Step 1: Follow these instructions to get started</p>
              <List type="bullet">
                <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            Go to settings and select Customer accounts. Click on Edit and enable the account by checking on the radio button.
                          </div>
                          <div style={{ width: '830px', height: '300px' }}>
                            <Image source={accountEnable} alt="account-enable" style={{ width: '100%', height: '100%' }} />
                          </div>
                    </List.Item>
                <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                            Click on the Social Provider you want to configure. 
                          </div>
                        
                          <div style={{ width: '830px', height: '300px' }}>
                            <Image source={socialProvider} alt="socialProvider" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                  
                  <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                            Add your Google Client ID, Google Client Secret, and Google scope. To generate Google Client ID and Google Client Secret, navigate to console.developers.google.com.
                          </div>
                        
                          <div style={{ width: '830px', height: '450px' }}>
                            <Image source={configuration} alt="Configuration" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                  
                  <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                          Go to Sales channels and select Online Store, then click on customize.

                            Go to the frontend login page and click on the Social Provider.
                          </div>
                        
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '450px' }}>
                            <Image source={customize} alt="customize" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                          Since the icons are not visible. Toggle the button. 
                          
                          </div>
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '300px' }}>
                            <Image source={disable} alt="disable" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                          As you can see the icons are now visible. You can navigate to your store login page.
                          </div>
                        
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '300px' }}>
                            <Image source={loginEnable} alt="enable-page" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                      
                          <div style={{ marginBottom: '10px' }}>
                            Select the social provider using which you want to login to the store.
                          </div>
                        
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '300px' }}>
                            <Image source={loginPage} alt="login-page" style={{ width: '100%', height: '100%' }} />
                          </div>
                       
                    </List.Item>
                  </VerticalStack>
                </VerticalStack>
              </List>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}