import { useState, useEffect } from 'react';
import { DataTable, Card, Button, Frame, Page } from '@shopify/polaris';

export default function PricingPlanPage() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [activeProPlans, setActiveProPlans] = useState();
  const [activeBasicPlans, setActiveBasicPlans] = useState();
  
  useEffect(() => {
    const fetchActivePlans = async () => {
      
      try {
        const response = await fetch(`/api/active_plans?shop=${storeDomain}`);
        const data = await response.json();
        console.log(data);
        if(data[0].name === "Basic Plan"){
          setActiveBasicPlans(data[0].status);
        } else if (data[0].name === "Premium Plan"){
          setActiveProPlans(data[0].status);
        }
      } catch (error) {
        console.error('Error fetching active plans:', error);
      }
    };
  
    fetchActivePlans();
  }, [storeDomain]);

  // const handleBasicPlanClick = async () => {

  //   try {
  //     const response = await fetch(`/api/basic_plan?shop=${storeDomain}`, {
  //       method: "GET",
  //     });
  
  //     if (response.ok) {
  //       const confirmationUrl = await response.text();
  //       console.log("Confirmation URL: " + confirmationUrl);
  //       window.open(confirmationUrl, '_blank');
  //     } else {
  //       console.log('Failed to get confirmation URL.');
  //     }
  //   } catch (error) {
  //     console.error('Error handling basic plan click:', error);
  //   }
  // };  

  const handlePremiumPlanClick = async () => {

    try {
      const response = await fetch(`/api/premium_plan?shop=${storeDomain}`, {
        method: "GET",
      });
  
      if (response.ok) {
        const confirmationUrl = await response.text();
        console.log("Confirmation URL: " + confirmationUrl);
        window.open(confirmationUrl, '_blank');
      } else {
        console.log('Failed to get confirmation URL.');
      }
    } catch (error) {
      console.error('Error handling basic plan click:', error);
    }
  };

  const greentick = <center><span style={{ color: 'green' }}>✓</span></center>;
  const activePlanText = (activeProPlans === 'active') ? <span style={{ color: 'green' }}>Active Premium Plan</span> : <span style={{ color: 'green' }}>Active Basic Plan</span>;
  const rows = [
    ['Pricing',<center><b>$0</b></center>, <center><b>$4.99</b></center>],
    ['Social Login / Registration', <center>1000/month</center>, <center>Unlimited</center>],
    ['20+ Social Login Networks', greentick, greentick],
    ['Customise Social Icons', greentick, greentick],
    ['Login User Reports', greentick, greentick],
    ['Redirect URL Path', greentick, greentick],
    ['Icons Embed Code', greentick, greentick],
    ['Google One Tap Login', greentick, greentick],
    ['Domain Restriction', greentick, greentick],
    ['Geolocation Restriction', greentick, greentick],
    ['Mailchimp Integration', greentick, greentick],
    ['Klaviyo Integration', greentick, greentick],
    ['Email Verification', greentick, greentick],
    ['Standard Support', '', greentick],
    ['Upgrade Plan', <Button>{activeBasicPlans ? activePlanText : 'Free Plan'}</Button>, 
    // <Button primary onClick={handleBasicPlanClick}>{activeBasicPlans ? "Basic Activated" : 'Select Plan ($2.99/month)'}</Button>, 
    <Button primary onClick={handlePremiumPlanClick}>{activeProPlans ? "Premium Activated" : 'Select Plan ($4.99/month)'}</Button>],
  ];

  const columns = [
    {
      header: 'Features',
      accessor: 0,
    },
    {
      header: <center>Free Plan</center>,
      accessor: 1,
    },
    // {
    //   header: <center>Basic Plan</center>,
    //   accessor: 2,
    // },
    {
      header: <center>Premium Plan</center>,
      accessor: 2,
    }
  ];

  return (
    <div style={{ height: '500px' }}>
    <Frame>
      <Page
        title="Pricing Plan"
      >
      <Card>
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text', 'text']}
          headings={columns.map((column) => column.header)}
          rows={rows}
        />
      </Card>
      </Page>
    </Frame>
  </div>
  );
};
