import { useState, useEffect } from 'react';
import { Page, Form, Layout, Card, Tabs, TextField, Checkbox, Toast, Frame } from '@shopify/polaris';

function ConfigurationTab() {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [redirectUrl, setRedirectUrl] = useState('');
  const [embedCodeIcon, setEmbedCodeIcon] = useState('');
  const [multipassToken, setMultipassToken] = useState('');
  const [isMultipassLoginEnabled, setIsMultipassLoginEnabled] = useState(false);
  const [isGoogleOneTapLoginEnabled, setIsGoogleOneTapLoginEnabled] = useState(false);
  const [isGoogleOauthConsentEnabled, setIsGoogleOauthConsentEnabled] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.integrations.configuration;
            setRedirectUrl(dataStore.redirectUrl);
            setEmbedCodeIcon(dataStore.embedCodeIcon);
            setMultipassToken(dataStore.multipassToken);
            setIsMultipassLoginEnabled(dataStore.isMultipassLoginEnabled);
            setIsGoogleOneTapLoginEnabled(dataStore.isGoogleOneTapLoginEnabled);
            setIsGoogleOauthConsentEnabled(dataStore.isGoogleOauthConsentEnabled);
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to save settings');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleSubmitConfiguration = async () => {

    const configuration = {
      redirectUrl,
      embedCodeIcon,
      isMultipassLoginEnabled,
      multipassToken,
      isGoogleOneTapLoginEnabled,
      isGoogleOauthConsentEnabled,
    };

    const response = await fetch(`/api/update_data?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({configuration}),
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');
    }
    setShowToast(true);
  };

  const handleToastDismiss = () => {
    setShowToast(false);
  };  

  return (
    <div>
    <Frame>
    <Page
      title="Redirection Path"
      primaryAction={{
        content: 'Save',
        onAction: handleSubmitConfiguration,
      }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Redirect Path"
          description="Enter the URL path you want to redirect to"
        >
          <Card>
            <Form>
              <TextField
                label="Redirect Path"
                value={redirectUrl}
                onChange={setRedirectUrl}
                placeholder='/cart'
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Embed code for social icons"
          description="Enter an ID for element where you want to use social icons"
        >
          <Card>
            <Form>
              <TextField
                label="Embed Code"
                value={embedCodeIcon}
                onChange={setEmbedCodeIcon}
                placeholder='customer_login'
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Multipass Login"
          description="Enable Multipass Login and enter the Multipass Token"
        >
          <Card>
            <Form>
              <Checkbox
                label="Enable Multipass Login"
                checked={isMultipassLoginEnabled}
                onChange={setIsMultipassLoginEnabled}
              />
              <TextField
                label="Multipass Token"
                value={multipassToken}
                onChange={setMultipassToken}
                placeholder="Enter Multipass Token"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Google One Tap Login"
          description="Enable or Disable Google One Tap Login"
        >
          <Card>
            <Form>
              <Checkbox
                label="Enable Google One Tap Login"
                checked={isGoogleOneTapLoginEnabled}
                onChange={setIsGoogleOneTapLoginEnabled}               
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Google Change OAuth Consent Screen"
          description="Enable Option to Change Google OAuth Consent Screen Domain"
        >
          <Card>
            <Form>
              <Checkbox
                label="Enable Configure Google Consent Domain"
                checked={isGoogleOauthConsentEnabled}
                onChange={setIsGoogleOauthConsentEnabled}
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {showToast && (
        <Toast
          content={toastMessage}
          onDismiss={handleToastDismiss}
          duration={3000}
        />
      )}
    </Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    </Frame>
    </div>
  );
}

function RestrictionTab() {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [allowedDomain, setAllowedDomain] = useState('');
  const [allowedCountryCode, setAllowedCountryCode] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.integrations.restriction;
            setAllowedDomain(dataStore.allowedDomain);
            setAllowedCountryCode(dataStore.allowedCountryCode);
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to save settings');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleSubmitRestriction = async () => {

      const restriction = {
        allowedDomain,
        allowedCountryCode,
      };
  
      const response = await fetch(`/api/update_data?shop=${storeDomain}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({restriction}),
      });
  
      if (response.ok) {
        setToastMessage('Settings saved successfully.');
      } else {
        setToastMessage('Failed to save settings.');
      }
  
      setShowToast(true);
  };

  const handleToastDismiss = () => {
    setShowToast(false);
  };

  return (
    <div>
    <Frame>
    <Page
      title="Restriction"
      primaryAction={{
        content: 'Save',
        onAction: handleSubmitRestriction,
      }}
    >
      
      <Layout>
        <Layout.AnnotatedSection
          title="Domain Restriction"
          description="Enter the allowed domain"
        >
          <Card>
            <Form>
              <TextField
                label="Allowed Domain (separated by semicolons)"
                value={allowedDomain}
                onChange={setAllowedDomain}
                placeholder="gmail.com; yahoo.com"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Geolocation Restriction"
          description="Enter the allowed country code"
        >
          <Card>
            <Form>
              <TextField
                label="Allowed Country Code (separated by semicolons)"
                value={allowedCountryCode}
                onChange={setAllowedCountryCode}
                placeholder="US; CA; GB"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {showToast && (
        <Toast
          content={toastMessage}
          onDismiss={handleToastDismiss}
          duration={3000}
        />
      )}
    </Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    </Frame>
    </div>
  );
}

function MailIntegrationTab() {

  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [isMailchimpIntegration, setIsMailchimpIntegration] = useState(false);
  const [mailchimpApiKey, setMailchimpApiKey] = useState('');
  const [mailchimpAudienceId, setMailchimpAudienceId] = useState('');
  const [isKlaviyoIntegration, setIsKlaviyoIntegration] = useState(false);
  const [klaviyoApiKey, setKlaviyoApiKey] = useState('');
  const [klaviyoAudienceId, setKlaviyoAudienceId] = useState('');
  const [isEmailVerificationEnabled, setIsEmailVerificationEnabled] = useState(false);
  const [emailVerificationText, setEmailVerificationText] = useState('Enter OTP sent to your email:');
  const [otpVerificationText, setOtpVerificationText] = useState('Enter Your OTP here:');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.integrations.mailintegration;
            setIsMailchimpIntegration(dataStore.isMailchimpIntegration);
            setMailchimpApiKey(dataStore.mailchimpApiKey);
            setMailchimpAudienceId(dataStore.mailchimpAudienceId);
            setIsKlaviyoIntegration(dataStore.isKlaviyoIntegration);
            setKlaviyoApiKey(dataStore.klaviyoApiKey);
            setKlaviyoAudienceId(dataStore.klaviyoAudienceId);
            setIsEmailVerificationEnabled(dataStore.isEmailVerificationEnabled);
            setEmailVerificationText(dataStore.emailVerificationText);
            setOtpVerificationText(dataStore.otpVerificationText);
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to save settings');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleSubmitMailIntegration = async () => {

    const mailintegration = {
      isMailchimpIntegration,
      mailchimpApiKey,
      mailchimpAudienceId,
      isKlaviyoIntegration,
      klaviyoApiKey,
      klaviyoAudienceId,
      isEmailVerificationEnabled,
      emailVerificationText,
      otpVerificationText,
    };

    const response = await fetch(`/api/update_data?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({mailintegration}),
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');
    }

    setShowToast(true);
  };

  const handleToastDismiss = () => {
    setShowToast(false);
  };

  return (
    <div>
    <Frame>
    <Page
      title="Mail Integration"
      primaryAction={{
        content: 'Save',
        onAction: handleSubmitMailIntegration,
      }}
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Mailchimp Integration"
          description="Enter your Mailchimp API Key and Audience ID"
        >
          <Card>
            <Form>
              <Checkbox
                label="Enable Mailchimp integration"
                checked={isMailchimpIntegration}
                onChange={setIsMailchimpIntegration}
              />
              <TextField
                label="Mailchimp API Key"
                value={mailchimpApiKey}
                onChange={setMailchimpApiKey}
                placeholder="Enter your Mailchimp API Key"
                autoComplete='off'
              />
              <TextField
                label="Mailchimp Audience ID"
                value={mailchimpAudienceId}
                onChange={setMailchimpAudienceId}
                placeholder="Enter your Mailchimp Audience ID"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Klaviyo Integration" 
          description="Enter your Klaviyo List ID and API Key" 
        >
          <Card>
            <Form>
              <Checkbox
                checked={isKlaviyoIntegration} 
                onChange={setIsKlaviyoIntegration} 
                label="Enable Klaviyo Integration" 
              />
              <TextField
                label="Klaviyo List ID" 
                value={klaviyoAudienceId} 
                onChange={setKlaviyoAudienceId} 
                placeholder="Enter your Klaviyo List ID"
                autoComplete='off'
              />
              <TextField
                label="Klaviyo API Key" 
                value={klaviyoApiKey} 
                onChange={setKlaviyoApiKey} 
                placeholder="Enter your Klaviyo API Key"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Email Verification"
          description="Enter require message for email verfication form"
          >
          <Card>
            <Form>
              <Checkbox
                checked={isEmailVerificationEnabled}
                onChange={setIsEmailVerificationEnabled}
                label="Enable Email Verification"
              />
              <TextField
                label="Text for Email Verification"
                value={emailVerificationText}
                onChange={setEmailVerificationText}
                placeholder="Enter Text for Email Verification"
                autoComplete='off'
              />
              <TextField
                label="Text for OTP Verification"
                value={otpVerificationText}
                onChange={setOtpVerificationText}
                placeholder="Enter Text for OTP Verification"
                autoComplete='off'
              />
            </Form>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {showToast && (
        <Toast
          content={toastMessage}
          onDismiss={handleToastDismiss}
          duration={3000}
        />
      )}
    </Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    <Page></Page>
    </Frame>
    </div>
  );
}

export default function TabsComponent() {

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  return (
    <div>
      <Page title="Configuration Settings">
        <Layout>
          <Layout.Section>
            <Tabs
              tabs={[
                {
                  id: 'configurationTab',
                  content: 'Configuration',
                  accessibilityLabel: 'Configuration Tab',
                  panelID: 'configurationTabPanel',
                },
                {
                  id: 'restrictionTab',
                  content: 'Restriction',
                  accessibilityLabel: 'Restriction Tab',
                  panelID: 'restrictionTabPanel',
                },
                {
                  id: 'mailIntegrationTab',
                  content: 'Mail Integration',
                  accessibilityLabel: 'Mail Integration Tab',
                  panelID: 'mailIntegrationTabPanel',
                },
              ]}
              selected={selectedTab}
              onSelect={handleTabChange}
            />
            <div hidden={selectedTab !== 0}>
                <ConfigurationTab />
            </div>
            <div hidden={selectedTab !== 1}>
                <RestrictionTab />
            </div>
            <div hidden={selectedTab !== 2}>
                <MailIntegrationTab />
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
}
