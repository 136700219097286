import React, { useState, useEffect } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Page, Frame, Tabs } from '@shopify/polaris';
import HomePage from './views/index.jsx';
import Customize from './views/customize.jsx'
import TabsComponent from './views/integration.jsx';
import UserReport from './views/userreport.jsx';
import InstructionPage from './views/guidelines.jsx';
import PricingPlanPage from './views/pricingplan.jsx';

export default function App() {

  const url = new URL(window.location).searchParams;
  const [activeTab, setActiveTab] = useState(0);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if(!url.get("shop") && !url.get("host")){
      setShowWarning(true);
    } else if (url.get("shop") && url.get("host") && url.get("shop").endsWith(".myshopify.com")) {
      setShowWarning(false);
    }
  }, [url]);

  return showWarning ? (
    <Page>Invalid Access</Page>
  ) : (
    <AppProvider i18n={enTranslations}>
      <Frame>
        <Page title="Elite Social Login">
          <Tabs
            tabs={[
              { id: 'tab1', content: 'Configuration' },
              { id: 'tab2', content: 'Customize Icons', },
              { id: 'tab3', content: 'Integrations' },
              { id: 'tab4', content: 'User Report' },
              { id: 'tab5', content: 'Guidelines' },
              { id: 'tab6', content: 'Pricing plans' },
            ]}
            selected={activeTab}
            onSelect={(selectedTabIndex) => setActiveTab(selectedTabIndex)}
          />
          <div>
            {activeTab === 0 && <HomePage />}
            {activeTab === 1 && <Customize />}
            {activeTab === 2 && <TabsComponent />}
            {activeTab === 3 && <UserReport />}
            {activeTab === 4 && <InstructionPage />}
            {activeTab === 5 && <PricingPlanPage />}
          </div>
        </Page>
      </Frame>
    </AppProvider>
  );
}
